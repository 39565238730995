export enum GameModeType {
  // Super-Regions
  Globe = "Globe",
  Europe = "Europe",
  Asia = "Asia",
  Americas = "Americas",
  Africa = "Africa",

  // Europe
  WestEurope = "West Europe",
  NorthEurope = "North Europe",
  CentralEurope = "Central Europe",
  SouthEastEurope = "South East Europe",
  NorthEastEurope = "North East Europe",

  //Africa
  NorthAfrica = "North Africa",
  WestAfrica = "West Africa",
  CentralAfrica = "Central Africa",
  EastAfrica = "East Africa",
  SouthernAfrica = "Southern Africa",
  
  //Asia
  NorthEastAsia = "North East Asia",
  SouthAsia = "South Asia",
  SouthEastAsia = "South East Asia",
  CentralAsia = "Central Asia",
  
  //Americas
  NorthAmerica = "North America",
  CentralAmerica = "Central America",
  Caribbean = "Caribbean",
  SouthAmerica = "South America",

  //Other
  Oceania = "Oceania",
  MiddleEast = "Middle East",

  // Custom
  Custom = "Custom"
}