import { superRegions } from "../config/superRegions";
import { quizOptionsObject } from "../config/quizOptionsObject";
import { GameModeType } from "../types/GameModeType";
import { GameDifficultyType } from "../types/GameDifficultyType";

export const modeKeyMap: Record<GameModeType, string> = {
  // Europe
  [GameModeType.WestEurope]: "WestEurope",
  [GameModeType.NorthEurope]: "NorthEurope",
  [GameModeType.CentralEurope]: "CentralEurope",
  [GameModeType.SouthEastEurope]: "SouthEastEurope",
  [GameModeType.NorthEastEurope]: "NorthEastEurope",

  // Africa
  [GameModeType.NorthAfrica]: "NorthAfrica",
  [GameModeType.WestAfrica]: "WestAfrica",
  [GameModeType.CentralAfrica]: "CentralAfrica",
  [GameModeType.EastAfrica]: "EastAfrica",
  [GameModeType.SouthernAfrica]: "SouthernAfrica",

  // Asia
  [GameModeType.NorthEastAsia]: "NorthEastAsia",
  [GameModeType.SouthAsia]: "SouthAsia",
  [GameModeType.SouthEastAsia]: "SouthEastAsia",
  [GameModeType.CentralAsia]: "CentralAsia",

  // Americas
  [GameModeType.NorthAmerica]: "NorthAmerica",
  [GameModeType.CentralAmerica]: "CentralAmerica",
  [GameModeType.Caribbean]: "Caribbean",
  [GameModeType.SouthAmerica]: "SouthAmerica",

  // Other
  [GameModeType.Oceania]: "Oceania",
  [GameModeType.MiddleEast]: "MiddleEast",

  // Super-Regions
  [GameModeType.Americas]: "Americas",
  [GameModeType.Europe]: "Europe",
  [GameModeType.Asia]: "Asia",
  [GameModeType.Africa]: "Africa",
  [GameModeType.Globe]: "Globe",

  // Custom
  [GameModeType.Custom]: "Custom"
};

export const getRandomOptions = ( 
  correctAnswer: string, 
  gameMode: GameModeType, 
  gameDifficulty: GameDifficultyType,
  customCountries?: string[] 
): string[] => {
  const collectedCountries = new Set<string>();
  const formattedGameMode = modeKeyMap[gameMode]

  // Use custom countries if provided and not empty
  if (customCountries && customCountries.length >= 2) {
    customCountries.forEach(country => collectedCountries.add(country));
  } else {
    // Determine if gameMode is a region or a superRegion
    if (quizOptionsObject[formattedGameMode]) {
      // It's a region
      quizOptionsObject[formattedGameMode].forEach((country) => collectedCountries.add(country));
    } else {
      // It's a superRegion
      const regions = superRegions[formattedGameMode];
      if (regions) {
        regions.forEach((region) => {
          const countries = quizOptionsObject[region];
          if (countries) {
            countries.forEach((country) => collectedCountries.add(country));
          }
        });
      } else {
        console.warn(`Game mode "${formattedGameMode}" not found in superRegions or quizOptionsObject.`);
      }
    }
  }

  // Filter out the correct answer from the collected countries
  const allCountries = Array.from(collectedCountries);
  const availableOptions = allCountries.filter((option) => option !== correctAnswer);

  // Determine number of incorrect options based on gameDifficulty
  let numIncorrectOptions: number;
  switch (gameDifficulty) {
    case GameDifficultyType.Easy:
      numIncorrectOptions = 1; // 1 incorrect option + correct answer = 2 options
      break;
    case GameDifficultyType.Medium:
      numIncorrectOptions = 2; // 2 incorrect options + correct answer = 3 options
      break;
    case GameDifficultyType.Hard:
    default:
      numIncorrectOptions = 3; // 3 incorrect options + correct answer = 4 options
      break;
  }

  // Handle case where available options are fewer than required
  numIncorrectOptions = Math.min(numIncorrectOptions, availableOptions.length);

  // Select random incorrect options
  const incorrectOptions: string[] = [];
  const usedIndices = new Set<number>();

  while (incorrectOptions.length < numIncorrectOptions && usedIndices.size < availableOptions.length) {
    const randomIndex = Math.floor(Math.random() * availableOptions.length);
    if (!usedIndices.has(randomIndex)) {
      usedIndices.add(randomIndex);
      incorrectOptions.push(availableOptions[randomIndex]);
    }
  }

  // Shuffle the options and include the correct answer
  const allOptions = [...incorrectOptions, correctAnswer];
  // Fisher-Yates shuffle
  for (let i = allOptions.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [allOptions[i], allOptions[j]] = [allOptions[j], allOptions[i]];
  }

  return allOptions;
};