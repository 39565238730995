import React from 'react'

import "./AYRLogo.css"

import { useGame } from '../../Context/GameContext'
import { GameStateType } from '../../types/GameStateType'

const AYRLogo: React.FC = () => {
  const { gameState } = useGame();

  if (gameState === GameStateType.Playing) {
    return null;
  }

  const handleClick = () => {
    window.location.href = "/";
  }

  return (
    <button className='AYRLogo' onClick={handleClick}>
      <h1>🌍 Nationality Guesser</h1>
    </button>
  )
}

export default AYRLogo;