import React, { useState } from 'react';
import './LoginModal.css';
import GoogleIcon from '../../assets/googleLogo.webp';
import { useAuth } from '../../Context/AuthContext';
import { FirebaseError } from 'firebase/app';

import { useAlert } from '../../Context/AlertContext';

interface LoginModalProps {
  setOpenLoginModal: (open: boolean) => void;
}

const LoginModal: React.FC<LoginModalProps> = ({ setOpenLoginModal }) => {
  const { showAlert } = useAlert()

  const { signInWithGoogle, signInWithEmailPassword, createUserWithEmailPassword } = useAuth();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const [isSignUp, setIsSignUp] = useState(true);

  const handleContainerClick = (event: React.MouseEvent) => {
    event.stopPropagation();
  };

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();
    setError('');
    try {
      if (isSignUp) {
        await createUserWithEmailPassword(email, password);
      } else {
        await signInWithEmailPassword(email, password);
      }
      setOpenLoginModal(false);
    } catch (error) {
      if (error instanceof FirebaseError) {
        setOpenLoginModal(false);
        
        switch (error.code) {
          case 'auth/email-already-in-use':
            showAlert('An account with this email already exists.', "red");
            break;
          case 'auth/invalid-email':
            showAlert('The email address is not valid.', "red");
            break;
          case 'auth/operation-not-allowed':
            showAlert('Email/password accounts are not enabled. Please contact support.', "red");
            break;
          case 'auth/weak-password':
            showAlert('The password is too weak. Please choose a stronger password.', "red");
            break;
          default:
            showAlert(`Failed to ${isSignUp ? 'create an account' : 'sign in'}. Please try again.`, "red");
        }
      } else {
        showAlert(`An unexpected error occurred. Please try again.`, "red");
        setOpenLoginModal(false);
      }
      console.error("Authentication error:", error);
    }
  };

  const handleGoogleSignIn = async () => {
    try {
      await signInWithGoogle();
      setOpenLoginModal(false);
    } catch (error) {
      setOpenLoginModal(false);
      console.error("Google sign-in error:", error);
    }
  };

  return (
    <div className='LoginModal' onClick={() => setOpenLoginModal(false)}>
      <div className="LoginModal-container" onClick={handleContainerClick}>
        <h2>{isSignUp ? 'Create an account' : 'Login to your account'}</h2>
        {isSignUp && (
          <h3>Save your progress, edit your profile, and add your friends.</h3>
        )}
        <button className='google-button' onClick={handleGoogleSignIn}>
          <img src={GoogleIcon} className='google-icon' alt="Google" />
          {isSignUp ? 'Sign-up with Google' : 'Login with Google'}
        </button>
        <div className="login-modal-separator">
          <hr />
          <p>or</p>
          <hr />
        </div>
        <form className='form' onSubmit={handleSubmit}>
          <input
            type="email"
            placeholder='Email'
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            onKeyDown={(e) => e.stopPropagation()}
            required
            autoComplete="current-email"
          />
          <input
            type="password"
            placeholder='Password'
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            onKeyDown={(e) => e.stopPropagation()}
            required
            autoComplete="current-password"
          />
          {error && <p className="error-text">{error}</p>}
          <button className='continue' type="submit">
            {isSignUp ? 'Sign Up' : 'Login'}
          </button>
        </form>
        <p>
          {isSignUp ? "Already have an account? " : "Don't have an account? "}
          <button className='login-create' onClick={() => setIsSignUp(!isSignUp)}>
            {isSignUp ? 'Login' : 'Create one'}
          </button>
        </p>
      </div>
    </div>
  );
};

export default LoginModal;