import React, {useState, useEffect} from 'react'
import { Helmet } from 'react-helmet-async'
import "./Body.css"

import Game from './Game/Game'
import Leaderboard from './Leaderboard/Leaderboard'
import Gamemodes from './Gamemodes/Gamemodes'
import Friends from './Friends/Friends'

import { GameStateType } from '../../types/GameStateType'

import { useGame } from '../../Context/GameContext'

interface BodyProps {
  setOpenLoginModal: (open: boolean) => void;
}

const Body: React.FC<BodyProps> = ({ setOpenLoginModal }) => {
const { gameState } = useGame();

  const [isMobile, setIsMobile] = useState<boolean>(window.innerWidth < 800);
  const [displaySideContainers, setDisplaySideContainers] = useState<boolean>(false);
  const [hideContainers, setHideContainers] = useState<boolean>(false);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 800);
    };

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    if (gameState === GameStateType.Playing && !isMobile) {
      setTimeout(() => setHideContainers(true), 300);
      setDisplaySideContainers(false);
    } else {
      setHideContainers(false);
      setDisplaySideContainers(true);
    }
  }, [gameState, isMobile]);

  return (
    <div className={isMobile ? 'Body-mobile' : `Body-desktop ${hideContainers ? "centered" : ""}`}>
      <Helmet>
        <title>Nationality Guesser | Fun Geography Quiz Game</title>
        <meta name="description" content="Play Nationality Guesser - a fun, educational quiz game where you test your knowledge by guessing nationalities of people from around the world." />
        <meta name="keywords" content="nationality guesser, quiz game, geography quiz, nationality quiz, guess countries, geography game" />
        <link rel="canonical" href="https://nationalityguesser.com/" />
        <script type="application/ld+json">
          {`
            {
              "@context": "https://schema.org",
              "@type": "Game",
              "name": "Nationality Guesser",
              "description": "A fun quiz game where players guess the nationality of people from around the world",
              "genre": ["Quiz Game", "Educational Game"],
              "numberOfPlayers": {
                "@type": "QuantitativeValue",
                "minValue": 1,
                "maxValue": 1
              },
              "url": "https://nationalityguesser.com"
            }
          `}
        </script>
      </Helmet>
      
      {isMobile ? (
        <>
          <Game />
          
          <div className="double-container">
            <Gamemodes setOpenLoginModal={setOpenLoginModal} />
          </div>
          
          <div className="double-container">
            <Friends setOpenLoginModal={setOpenLoginModal} />
            <Leaderboard />
          </div>
        </>
      ) : (
        <>
          {!hideContainers && (
            <div className={`double-container left ${!displaySideContainers ? 'slide-left' : ''}`}>
              <Leaderboard />
              <Friends setOpenLoginModal={setOpenLoginModal} />
            </div>
          )}

          <Game />

          {!hideContainers && (
            <div className={`double-container right ${!displaySideContainers ? 'slide-right' : ''}`}>
              <Gamemodes setOpenLoginModal={setOpenLoginModal} />
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default Body