import { useCallback } from 'react';
import { usePoints } from '../Context/PointsContext';

interface UseCheckAnswerArgs {
  correctAnswer: string;
  quizId: string;
  gameDifficulty: string;
  nextQuiz: () => void;
}

export const useCheckAnswer = ({
  correctAnswer,
  quizId,
  gameDifficulty,
  nextQuiz,
}: UseCheckAnswerArgs) => {
  const {
    handleCorrectAnswer,
    handleIncorrectAnswer,
  } = usePoints();

  const checkAnswer = useCallback(
    (option: string) => {

      if (option === correctAnswer) {
        handleCorrectAnswer(quizId, gameDifficulty);
        nextQuiz();
      } else {
        handleIncorrectAnswer(quizId, gameDifficulty);
      }
    },
    [
      correctAnswer,
      handleCorrectAnswer,
      handleIncorrectAnswer,
      quizId,
      gameDifficulty,
      nextQuiz,
    ]
  );

  return { checkAnswer };
};
