import React, { useEffect, useState } from 'react'

import "./Game.css"

import { useGame } from '../../../Context/GameContext'
import { GameStateType } from '../../../types/GameStateType'

import Instructions from './Instructions/Instructions'
import Playing from './Playing/Playing'
import StartGame from './StartGame/StartGame'
import CustomGamemode from './CustomGamemode/CustomGamemode'
import { GameModeType } from '../../../types/GameModeType'

const Game: React.FC = () => {
  const { gameState, setGameState, gameDifficulty, gameMode, setCustomCountries } = useGame();

  const [fullWidth, setFullWidth] = useState<boolean>(false);
  const [isMobile, setIsMobile] = useState<boolean>(window.innerWidth < 800);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 800);
    };

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    if (gameState === GameStateType.Playing && !isMobile) {
      setFullWidth(true)
    } else {
      setFullWidth(false)
    }
  }, [gameState, isMobile])

  const handleStartGameFromInstructions = () => {
    if (gameMode === GameModeType.Custom) {
      setGameState(GameStateType.CustomMode);
    } else {
      setGameState(GameStateType.Start);
    }
  };

  const handleCustomCountriesSelected = (countries: string[]) => {
    setCustomCountries(countries);
  };

  return (
    <div className={`Game ${(fullWidth) ? "fullWidthGame" : ""}`}>
      {gameState === GameStateType.Instructions && (
        <Instructions handleStartGameFromInstructions={handleStartGameFromInstructions} />
      )}

      {gameState === GameStateType.Start && (
        <StartGame setGameState={setGameState} gameDifficulty={gameDifficulty} gameMode={gameMode} />
      )}

      {gameState === GameStateType.CustomMode && (
        <CustomGamemode 
          setGameState={setGameState} 
          gameMode={gameMode} 
          onCustomCountriesSelected={handleCustomCountriesSelected} 
        />
      )}

      {(gameState === GameStateType.Playing || gameState === GameStateType.End) && (
        <Playing />
      )}
    </div>
  )
}

export default Game