import React, { useState, useEffect, useCallback } from 'react'

import "./Leaderboard.css"

import LeaderboardIcon from '@mui/icons-material/Leaderboard';

import { LeaderboardMemberType } from '../../../types/LeaderboardMemberType';

import LeaderboardMember from './LeaderboardMember/LeaderboardMember';

import { fetchTopUsersByGamemode } from '../../../services/firestoreService';
import { useGame } from '../../../Context/GameContext';

const Leaderboard: React.FC = () => {
  const { gameMode, gameState } = useGame()
  const [leaderboardMembers, setLeaderboardMembers] = useState<LeaderboardMemberType[]>([]);
  const [leaderboardType, setLeaderboardType] = useState<"Least" | "Most" | "Streak">("Least");

  const handleLeaderboardChange = useCallback(async (leaderboard: "Least" | "Most" | "Streak") => {
    let members: LeaderboardMemberType[];

    try {
      switch (leaderboard) {
        case "Least":
          members = await fetchTopUsersByGamemode(gameMode, "points");
          break;
        case "Most":
          members = await fetchTopUsersByGamemode(gameMode, "points");
          break;
        case "Streak":
          members = await fetchTopUsersByGamemode(gameMode, "highscoreStreak");
          break;
        default:
          members = [];
      }
      
      setLeaderboardMembers(members);
      setLeaderboardType(leaderboard);
    } catch (error) {
      console.error("Error fetching leaderboard data:", error);
      setLeaderboardMembers([]);
    }
  }, [gameMode]);

  useEffect(() => {
    const fetchData = async () => {
      handleLeaderboardChange(leaderboardType);
    };
    fetchData();
  }, [leaderboardType, gameState, handleLeaderboardChange]);

  const handleLeaderboardTypeClick = (type: "Least" | "Most" | "Streak") => {
    setLeaderboardType(type);
  }

  return (
    <div className='Leaderboard'>
      <div className="leaderboard-title">
        <LeaderboardIcon className='leaderboardIcon' sx={{ fontSize: 20 }} />
        <h2>Leaderboards</h2>
      </div>
      <div className='leaderboard-types'>
        <button onClick={() => handleLeaderboardTypeClick("Least")} className={leaderboardType === "Least" ? 'selected' : ''}>
        Points
        </button>
        <button onClick={() => handleLeaderboardTypeClick("Streak")} className={leaderboardType === "Streak" ? 'selected' : ''}>
          Streak
        </button>
      </div>
      <div className="leaderboard-members">
        {leaderboardMembers.length < 1 && (
          <p>Loading leaderboard...</p>
        )}
        {leaderboardMembers.map((member, index) => (
          <LeaderboardMember key={index} member={member} rank={index + 1} />
        ))}
      </div>
    </div>
  )
}

export default Leaderboard