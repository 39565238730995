import React, { useState } from 'react';

import "./GameEnded.css"

import { flagMap } from '../../../../../config/flagData';

import ReplayIcon from '@mui/icons-material/Replay';
import ShareIcon from '@mui/icons-material/Share';
import ShareModal from './ShareModal/ShareModal';

interface GameEndedProps {
  handlePlayAgain: () => void,
  correctAnswer: string,
}

const GameEnded: React.FC<GameEndedProps> = ({ handlePlayAgain, correctAnswer }) => {
  const [showShareModal, setShowShareModal] = useState(false);
  const flagImage = flagMap[correctAnswer];

  const handleShare = () => {
    setShowShareModal(true);
  }
  
  return (
    <div className="gameEndedContainer">
      <div className="end-text-container">
        <p>Incorrect, the answer was <span>{correctAnswer}</span>.</p>
        <img src={flagImage} alt={`${correctAnswer} flag`} />
      </div>

      <div className='gameEndedButtons'>
        <button className='gameShareButton' onClick={handleShare}>
          <ShareIcon sx={{ fontSize: 24 }} />
          <p>Share Result</p>
        </button>

        <button className='gamePlayAgain' onClick={handlePlayAgain}>
          <ReplayIcon sx={{ fontSize: 24 }} />
          <p>Play Again</p>
        </button>
      </div>

      {showShareModal && (
        <ShareModal 
          correctAnswer={correctAnswer} 
          onClose={() => setShowShareModal(false)} 
        />
      )}
    </div>
  )
}

export default GameEnded