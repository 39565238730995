import React, { createContext, useContext, ReactNode } from 'react';

import { usePointsSystem } from '../Hooks/usePointsSystem';

import { useAuth } from './AuthContext';

import { useGame } from './GameContext';
import { useAlert } from './AlertContext';

interface PointsSystemContext {
  currentPoints: number;
  previousLevelPoints: number;
  nextLevelPoints: number;
  currentLevelName: string;
  gameStreak: number;
  previousGameStreak: number;
  previousGamePoints: number;
  highscoreStreak: number;
  lastAnswer: boolean | null;
  skipCount: number;
  streakSaverCount: number;
  usedStreakSaver: boolean;
  handleSkipCountUsed: () => void;
  handleCorrectAnswer: (quizId: string, difficulty: string) => void;
  handleIncorrectAnswer: (quizId: string, difficulty: string) => void;
  handleChangeInGamemodeDifficulty: () => void;
}

const defaultContext: PointsSystemContext = {
  currentPoints: 0,
  previousLevelPoints: 0,
  nextLevelPoints: 50,
  currentLevelName: '1',
  gameStreak: 0,
  previousGameStreak: 0,
  previousGamePoints: 0,
  highscoreStreak: 0,
  lastAnswer: null,
  skipCount: 1,
  streakSaverCount: 0,
  usedStreakSaver: false,
  handleSkipCountUsed: () => {},
  handleCorrectAnswer: (quizId: string, difficulty: string) => {},
  handleIncorrectAnswer: (quizId: string, difficulty: string) => {},
  handleChangeInGamemodeDifficulty: () => {}
};

const PointsContext = createContext<PointsSystemContext>(defaultContext);

interface PointsProviderProps {
  children: ReactNode;
}

export const PointsProvider: React.FC<PointsProviderProps> = ({ children }) => {
  const { user } = useAuth();
  const { gameMode, setGameDifficulty, setGameState } = useGame();
  const { showAlert } = useAlert();

  let userAllowedSkips = 0;
  let streakSaverCount = 0;
  
  if (user?.userType === "anonymous") {
    userAllowedSkips = 0;
    streakSaverCount = 0;
  } else if (user?.userType === "authenticated") {
    if (user.subscription === "Premium") {
      userAllowedSkips = 5;
      streakSaverCount = 1;
    } else { // Standard subscription
      userAllowedSkips = 1;
      streakSaverCount = 0;
    }
  }

  const pointsHook = usePointsSystem(
    user?.uid || "", 
    gameMode, 
    userAllowedSkips, 
    streakSaverCount,
    setGameDifficulty, 
    setGameState,
    showAlert,
  );

  return (
    <PointsContext.Provider value={pointsHook}>
      {children}
    </PointsContext.Provider>
  );
};

export const usePoints = () => useContext(PointsContext);
