import React, { useEffect, useState } from 'react'

import "./Gamemodes.css"

import VideogameAssetIcon from '@mui/icons-material/VideogameAsset';

import { useGame } from '../../../Context/GameContext';
import { usePoints } from '../../../Context/PointsContext';
import { usePremium } from '../../../Context/PremiumContext';
import { useAuth } from '../../../Context/AuthContext';

import { GameModeType } from '../../../types/GameModeType';
import { GameStateType } from '../../../types/GameStateType';

import Gamemode from './Gamemode/Gamemode';

interface GamemodesProps {  
  setOpenLoginModal: (open: boolean) => void;
}

const Gamemodes: React.FC<GamemodesProps> = ({ setOpenLoginModal }) => {
  const { gameMode, gameState, setGameMode, setGameState, setCustomCountries } = useGame()
  const { handleChangeInGamemodeDifficulty } = usePoints()
  const { showPremiumModal } = usePremium()
  const { user } = useAuth()

  const isGuest = user?.userType === "anonymous";
  const isPremiumUser = user?.userType === "authenticated" && user?.subscription === "Premium";

  const [isMobile, setIsMobile] = useState<boolean>(false);

  useEffect(() => {
    const checkDimensions = () => {
      const { innerWidth } = window;
      setIsMobile(innerWidth < 800)
    };

    checkDimensions();
    window.addEventListener('resize', checkDimensions);

    return () => window.removeEventListener('resize', checkDimensions);
  }, []);

  const handleModeClick = (selectedGameMode: GameModeType) => {
    setGameMode(selectedGameMode)
    setGameState(GameStateType.Start)
    handleChangeInGamemodeDifficulty()
    setCustomCountries([])
    if (isMobile) {
      window.scrollTo({
        top: 100,
        behavior: 'smooth'
      });
    }
  };

  const handleCustomize = () => {

    if (isGuest) {
      setOpenLoginModal(true)
      return
    }

    if (!isGuest && !isPremiumUser) {
      showPremiumModal()
    }

    setGameMode(GameModeType.Custom)
    setGameState(GameStateType.CustomMode)
    handleChangeInGamemodeDifficulty()
    setCustomCountries([])
    if (isMobile) {
      window.scrollTo({
        top: 100,
        behavior: 'smooth'
      });
    }
  };

  return (
    <div className='Gamemodes'>
      <div className="gamemodes-title">
        <VideogameAssetIcon className='gamemodesIcon' sx={{ fontSize: 20 }} />
        <h2>Gamemodes</h2>
      </div>
      <div className="gamemodes-container">
        <button 
          className={`custom-gamemode-button ${(gameMode === GameModeType.Custom || gameState === GameStateType.CustomMode) ? 'GamemodeSelected' : ''}`} 
          onClick={handleCustomize}
        >
          <h4 className='custom-gamemode-title'>Custom</h4>
          <p className='custom-gamemode-description'>Play with the countries <strong>you</strong> want!</p>
        </button>

        {Object.values(GameModeType)
        .filter(mode => mode !== GameModeType.Custom)
        .map(mode => (
          <Gamemode
            key={mode}
            gameMode={mode}
            className={gameMode === mode ? "GamemodeSelected" : "Gamemode"}
            onClick={() => handleModeClick(mode)}
            disabled={false}
          />
        ))}
      </div>
    </div>
  )
}

export default Gamemodes